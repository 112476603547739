@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-feature-settings: 'kern';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll !important;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scroll::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Styles the scrollbar track for elements with class 'styled-scroll' */
  .styled-scroll::-webkit-scrollbar-track {
    background: black;
  }

  /* Styles the scrollbar handle for elements with class 'styled-scroll' */
  .styled-scroll::-webkit-scrollbar-thumb {
    background: #14f5da; /* Replace 'neonGreen' with your preferred shade */
    border-radius: 8px;
  }

  /* Optional: Styles the scrollbar itself (width/height) for elements with class 'styled-scroll' */
  .styled-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}
